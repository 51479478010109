// Adaptive placeholder defaults
// ------------------------------
// PRIVATE - default settings for adaptive placeholder

$ap-default: (
  height: 3em,
  margin: 1em,
  border: 1px,
  border-radius: 0,
  font-size: 1em,
  border-color: #ccc,
  label-color: $color-gold,
  active-color: $color-gold,
  valid-color: $color-gold,
  invalid-color: $color-pink,
  placeholder-background-color: transparent,
  textarea: false,
  textarea-height: 7em
);

// Default User Placeholder Settings
// ------------------------------
// - Allows $ap-default to be overwritten by default user settings

$adaptive-placeholder: ();

// adaptive-placeholder
// --------------------
// - Creates adaptive placeholder

@mixin adaptive-placeholder($map: $adaptive-placeholder) {
  // Only overwrite ap defaults if $map is $adaptive-placeholder
  @if $map == $adaptive-placeholder {
    $ap-default: map-merge($ap-default, $map) !global;
    $map: $ap-default;
  }
  // Updates default map with new values
  $map: map-merge($ap-default, $map);
  // Gets variables
  $height: map-get($map, height);
  $margin: map-get($map, margin);
  $border: map-get($map, border);
  $radius: map-get($map, border-radius);
  $font-size: map-get($map, font-size);
  $border-color: map-get($map, border-color);
  $label-color: map-get($map, label-color);
  $active-color: map-get($map, active-color);
  $valid-color: map-get($map, valid-color);
  $invalid-color: map-get($map, invalid-color);
  $placeholder-background: map-get($map, placeholder-background-color);
  $isTextarea: map-get($map, textarea);
  $textarea-height: map-get($map, textarea-height);
  $borders: $border * 2;

  // Warnings for best practices
  @if $height < 2 * $margin {
    @warn "height needs to be at least 2x margin";
  }
  @if $height < $font-size {
    @warn "height needs to be at least >= font-size";
  }

  // Input / Textarea properties
  @include transition(all 0.2s ease-out);
  @include box-shadow(none);
  height: $height;
  height: calc(#{$height} - -#{$borders});
  font-size: $font-size;
  border-radius: $radius;
  resize: none;
  outline: none;
  &:focus,
  &:active {
    & + label:before,
    & + .adpl-label:before {
      color: $active-color;
    }
  }
  // Placeholder Styles
  & + label,
  & + .adpl-label {
    display: block;
    font-size: $font-size;
    pointer-events: none;
    overflow: hidden;
    span.label-content {
      display: none;
    }

    // Vertically align label with input
    @if $isTextarea {
      position: relative;
      top: 0;
      height: $textarea-height;
      height: calc(#{$textarea-height} - -#{$borders});
      line-height: 1;
      padding-top: $margin;
      padding-top: calc(#{$margin} - -#{$border});
    } @else {
      height: $height;
      line-height: 3.3em;
      text-indent: 0;
    }
    // Offset label correctly with negative margins
    @if $isTextarea {
      margin-top: $textarea-height;
      margin-top: calc(-#{$textarea-height} - #{$borders});
    } @else {
      margin-top: $height;
      margin-top: calc(-#{$height} - #{$border});
    }
    margin-bottom: 1em;
    cursor: text;
    &:before {
      // Initial Text
      content: attr(placeholder);
      display: inline-block;
      color: $label-color;
      margin: 0 $margin;
      margin: 0 calc(#{$margin} - -#{$borders});
      white-space: nowrap;
      // Transformation properties
      -webkit-transition-property: -webkit-transform, color;
      transition-property: transform, color;
      -webkit-transition-duration: 0.2s;
      transition-duration: 0.2s;
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
      // Set origin to left center so it scales to the left instead of center center
      transform-origin: left center;

      // Heres a version without prefixes
      // transition-property: transform, color;
      // transition-duration: 0.2s;
      // transition-delay: 0s;
      // transition-timing-function: ease-out;
      // // Set origin to left center so it scales to the left instead of center center
      // transform-origin: left center;
    }
  }

  // Focused, active and valid states for input and placeholder
  // Shifts up and adds a background white
  &:focus,
  &.active,
  &.adpl__mode-label {
    & + label:before,
    & + .adpl-label:before {
      background: $placeholder-background;
      // set padding for left & right of tiny placeholder text
      line-height: 1;
      padding: 0 $border * 1.5;
      // Adjusted all calcs within translates according to to split up into various translateYs instead of using calc for IE 10 support
      // http://stackoverflow.com/questions/21469350/not-possible-to-use-css-calc-with-transformtranlatex-in-ie
      @if $isTextarea {
        // transform: translateY(calc((-#{$margin} - 0.5em) - #{$border * 0.5})) scale(0.8, 0.8);
        -webkit-transform: translateY(-75%) translateY(-$border * 0.5) scale(0.8, 0.8);
        transform: translateY(-75%) translateY(-$border * 0.5) scale(0.8, 0.8);
      } @else {
        -webkit-transform: translateY(-75%) translateY(-$border * 0.5) scale(0.8, 0.8);
        transform: translateY(-75%) translateY(-$border * 0.5) scale(0.8, 0.8);
      }
    }
  }
  // Change placeholder value once text is entered.
  &.adpl__mode-label {
    & + label:before {
      content: attr(alt);
    }
  }
  &:required {
    &.error,
    &:invalid {
      & + label:before,
      & + .adpl-label:before {
        color: $invalid-color;
      }
      &:empty,
      &[value=''],
      &:not(:checked),
      &.empty {
        & + label:before,
        & + .adpl-label:before {
          color: $valid-color;
        }
        &.error,
        &.touched {
          & + label:before,
          & + .adpl-label:before {
            color: $invalid-color;
          }
        }
        &.checkedEmpty {
          & + label:before,
          & + .adpl-label:before {
            color: $invalid-color;
          }
        }
        &:focus {
          & + label:before,
          & + .adpl-label:before {
            color: $active-color;
          }
        }
      }
    }
    &:valid {
      & + label:before,
      & + .adpl-label:before {
        color: $valid-color;
      }
      &.error {
        & + label:before,
        & + .adpl-label:before {
          color: $invalid-color;
        }
      }
    }
    &[data-error] {
      & + label:before,
      & + .adpl-label:before {
        color: $invalid-color;
      }
    }
  }
  &:focus {
    & + label:before,
    & + .adpl-label:before {
      color: $active-color;
    }
  }
}

// custom styles for inverted forms.
// .form--inverted {
//   .adpl--processed {
//     label:before {
//       background: $color-black;
//     }
//   }
// }
//
