@import '../theme-bootstrap';
@import '../global/adaptive-placeholders';

// Setting Defaults for Adaptive Placeholder
$adaptive-placeholder: (
  height: 3em,
  margin: 1em,
  border-radius: 0
);

// Calling adaptive placeholder for input
// code: adplfeature_v1 (used to grep a site for portions of code associated with a given feature)
.adpl {
  overflow: visible;
  &--processed {
    @at-root input#{&} {
      @include adaptive-placeholder(
        (
          height: map-get($ap-default, height),
          margin: map-get($ap-default, margin)
        )
      );
    }
    @at-root textarea#{&} {
      @include adaptive-placeholder(
        (
          height: map-get($ap-default, textarea-height),
          textarea: true
        )
      );
    }
  }
  input[type='email'],
  input[type='tel'],
  input[type='password'],
  input[type='text'],
  select {
    margin: 0;
    padding-top: 22px;
    @include adaptive-placeholder(
      (
        height: map-get($adaptive-placeholder, height),
        margin: map-get($adaptive-placeholder, margin)
      )
    );
    border-color: $color-pink-1;
    &.error {
      border-color: $color-error;
    }
  }
  .select label {
    display: none;
  }
  fieldset {
    overflow: visible;
    max-width: 100%;
    .form-item {
      margin-bottom: 20px;
    }
  }
  .form-item {
    position: relative;
    overflow: visible;
  }
}

// code: adplfeature_v1 (used to grep a site for portions of code associated with a given feature)

.adpl label[data-required]:before {
  content: '';
}
